@import 'src/scss/variables/colours';
@import 'src/scss/variables/typography';
@import 'src/scss/utilities/mixins';
@import 'src/scss/utilities/breakpoints';

.footer {
    @apply relative px-4;
    @apply py-6 lg:px-12 lg:py-8;
    background-color: $green-dark;
    color: $white;
    min-height: 25vh;
    padding-bottom: var(--fixed-button-height);
    @include md-up {
        bottom: 0;
    }
}

.footer--wrapper {
    @apply flex flex-wrap lg:flex-nowrap;
    &-logo {
        @apply w-full md:mb-0 lg:w-1/2;
    }
    &-navigation {
        @apply grid w-full grid-cols-2 flex-wrap;
        @apply lg:flex lg:w-1/2 lg:flex-wrap;
    }
}

.divider--wrapper {
    @apply relative mt-6 w-full border-t border-white lg:mt-0;
}

.copyright--wrapper {
    @apply flex flex-wrap pt-6;
}

.copyright {
    @apply mb-8 md:mb-4;
    span,
    p {
        display: block;
    }

    span {
        @apply font-body font-semibold text-blue-extra-light;
        font-size: $font-regular;
    }
    p {
        @apply text-white;
        font-size: $font-xsmall;
    }
}

.navigation {
    ul {
        @apply pt-2;
        padding-right: 0.25rem;
    }
    a {
        @apply gap-y-4 font-semibold;
        &:hover {
            color: $blue-extra-light;
        }
    }
    a:last-child {
        /* CSS style properties */
        // @apply bg-black;
    }
}

.footer-logos {
    @apply h-10 pt-4 lg:pt-4;
}
